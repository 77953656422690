import React, { useState, useEffect } from 'react';
import {format ,parseISO} from 'date-fns'
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import client ,{ createAdhoc } from '../feathers';


export const EmployeeStatus = ({employee}) =>{

    console.log("EmployeeStatus employee:",employee);
    const [employeeStatus,setEmployeeStatus] = useState();


    useEffect(()=>{
        const handleEmployeeStatusChanged = (employeeStatusUpdate)=>{
            if(employeeStatus && employeeStatusUpdate.id === employeeStatus.id){
                console.log("NEW employee-status:", employeeStatusUpdate);
                setEmployeeStatus(employeeStatusUpdate);
            }else if(employeeStatusUpdate.id === employee.id){
                setEmployeeStatus(employeeStatusUpdate);
            }
        }    

        async function initServices(){
            const service = client.service("employee-status");
            service.on('created',(employeeStatusUpdate) => handleEmployeeStatusChanged(employeeStatusUpdate));
            service.on('updated',(employeeStatusUpdate) => handleEmployeeStatusChanged(employeeStatusUpdate));
            const status = await service.get(employee.id);
            console.log("status:", status);
            setEmployeeStatus(status);
        }
        initServices();
    },[employee])

    const handleClickButton = (available) =>{
        console.log("handleClickButton: "+ available);
        createAdhoc(employeeStatus.id,!available);
    }

    const renderButton = (available) =>{
        return <Button onClick={() => handleClickButton(available)}>{available?'Auf abwesend setzen':'Auf verfügbar setzen'}</Button>
    }

    const renderStatus = () =>{
        var available = false;
        if(!employeeStatus){
            return <h1>Ihr Status konnte nicht ermittelt werden.</h1>
        } else {
            available = employeeStatus.available;
        }
        return <div className="p-d-flex p-jc-center" style={{backgroundColor:'rgba(255,255,255,0)'}}>
                <div  className='p-shadow-24'> 
                <Card style={{backgroundColor:'rgb(255,255,255,0.1)' }} title={available?'Ich bin verfügbar':'Ich bin NICHT verfügbar'}  footer={renderButton(available)}>
                    <div className="p-d-flex p-jc-center">
                        <img alt='' src={available?'../icons/03_BG_status-ON.png':'../icons/03_BG_status-OFF.png'}/>
                    </div>
                    <div className="p-d-flex p-pb-2" >{employeeStatus.captureActivity.captureType?employeeStatus.captureActivity.captureType.name + ' ' + format(parseISO(employeeStatus.captureActivity.timestamp),'HH:mm:ss'):'<KEINE aktuelle Erfassung am Terminal>'}</div>
                    <div className="p-d-flex" >Aktueller Zeiteintrag:</div>
                    <div className="p-d-flex" ><b>{employeeStatus.timeEntry.timeEntryCategory.name}</b> &nbsp; {employeeStatus.timeEntry.title}</div>
                    <div className="p-d-flex p-pb-2" >{format(parseISO(employeeStatus.timeEntry.start),'dd MMM HH:mm')}<b>&nbsp;bis&nbsp;</b>{format(parseISO(employeeStatus.timeEntry.end),'dd MMM HH:mm')}</div>
                    <div className="p-d-flex">Letzter Adhoc-Eintrag:</div> 
                    <div className="p-d-flex">{employeeStatus.adhoc && employeeStatus.adhoc.createdAt?format(parseISO(employeeStatus.adhoc.createdAt),'dd MMM HH:mm:ss') + ' = ' + (employeeStatus.adhoc.available?'verfügbar':'NICHT verfügbar') :'<>'}</div> 
                </Card>
                </div>
                </div>
    }

    return(
        <React.StrictMode>
           {renderStatus()}           
        </React.StrictMode>
    )

}