import { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { getData, updateData } from '../feathers';



export const ManageSimpleListPage = ({ servicename }) => {
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [alias, setAlias] = useState();
    const [display, setDisplay] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [list,setList] = useState([]);

    const setListValues = async () => {
        setList(await getData(servicename));
    }

    useEffect(() => {
        setListValues();
        handleChangeSelectedValue({...emptyValue})
    }, [servicename])

    const handleChangeSelectedValue = (value) => {
        setSelectedValue(value);
        setId(value.id);
        setName(value.name);
        setAlias(value.alias);
        setDisplay(value.display);
    }

    const emptyValue = {name:'',alias:'',display:''}

    const handleOnClickNewEntry = () => {
        updateData(servicename, { id, name, display, alias });
        handleChangeSelectedValue({...emptyValue})
        setListValues();
    }

    return (
        <div className="p-d-flex p-flex-column p-m-4">
            <DataTable value={list} dataKey="id" className="p-datatable-sm" selection={selectedValue} onSelectionChange={e => handleChangeSelectedValue(e.value)} >
                <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                <Column field="id" header="ID" style={{width:'40px'}} ></Column>
                <Column field="name" header="Name"  ></Column>
                <Column field="alias" header="Alias"  style={{width:'80px'}} ></Column>
                <Column field="display" header="Display"  ></Column>
            </DataTable>
            <br />
            <Divider align="left" type="dashed">
                <b>Neuer Listeneintrag</b> <Button className='p-ml-5' onClick={()=>handleChangeSelectedValue(emptyValue)}>Neuer Listeneintrag</Button>
            </Divider>
            <div className="p-fluid p-formgrid p-grid" style={{backgroundColor:'rgba(255,255,255,0.1)'}}>
                <div className="p-field p-col-3">
                    <label htmlFor="name" className="p-d-block" >Name</label>
                    <InputText value={name} id="newName" aria-describedby="name-help" className={(name === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="p-field p-col-2">
                    <label htmlFor="alias" className="p-d-block" >Alias</label>
                    <InputText value={alias} id="newAlias" aria-describedby="alias-help" className={(alias === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setAlias(e.target.value)} />
                </div>
                <div className="p-field p-col-3">
                    <label htmlFor="display" className="p-d-block">Display</label>
                    <InputText value={display} style={{ width: '150px' }} id="newDisplay" aria-describedby="display-help" className={(display === '' ? 'p-invalid' : '') + " p-d-block"} onChange={(e) => setDisplay(e.target.value)} />
                </div>
                <div className="p-field p-col-4 p-col-align-end">
                    <Button onClick={handleOnClickNewEntry} disabled={name === '' || alias === '' || display === ''} label={id?'Eintrag bearbeiten':'Neuen Eintrag hinzufügen'} />
                </div>
            </div>
        </div>
    )

}