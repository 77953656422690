import { useState , useLayoutEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns'
import './OperatorView.scss'

const bodyDataPresent = (site, count) => {
    return (
        <div className="p-d-flex p-jc-center" style={{ width: '100%' }}>
            <div style={{ width: '50px' }}>{site}</div>
            <div style={{ width: '20px' }}>{count}</div>
        </div>
    );
}
const headerDataPresent = (site, count) => {
    return (
        <div className="p-d-flex p-jc-center" style={{}}>
            <div>{site + ':' + count}</div>
        </div>
    );
}

const headerDataAvailable = ({ name, count, start, end }) => {
    return (
        <div className="p-d-flex p-flex-column" style={{ width: '100%' }}>
            <div className="p-d-flex p-jc-center">{"Radius " + name + ':' + count}</div>
            <div className="p-d-flex p-jc-center">{"[ " + (!end ? '> ' + start : (!start ? '< ' + end : start + ' - ' + end)) + ' ] SEC'}</div>
        </div>
    );
}

export const OperatorView = ({ user }) => {

    const [totalCountPresent, setTotalCountPresent] = useState(17);
    const [totalCountAvailable, setTotalCountAvailable] = useState(33);
    const [currentTime,setCurrentTime] = useState(new Date())

    useLayoutEffect(() => {
        function updateCurrentTime() {
            setCurrentTime(new Date());
        }
        var timeInterval = window.setInterval(function(){ updateCurrentTime(); }, 1000);
        updateCurrentTime();
        return () => {window.clearInterval(timeInterval)}
      }, []);

    const headerPresent = [
        { name: 'ONW', count: 8 },
        { name: 'OSW', count: 6 },
        { name: 'AA', count: 3 },
    ]

    const headerAvailable = [
        { name: '1', count: 10, start: undefined, end: 180 },
        { name: '2', count: 8, start: 181, end: 360 },
        { name: '3', count: 15, start: 360, end: undefined },
    ]

    const dataPresent = [
        { name: 'ZF', ONW: bodyDataPresent('ZF', 2), OSW: bodyDataPresent('ZF', 1), AA: bodyDataPresent('ZF', 0) },
        { name: 'GF', ONW: bodyDataPresent('GF', 4), OSW: bodyDataPresent('GF', 2), AA: bodyDataPresent('GF', 1) },
        { name: 'AGT', ONW: bodyDataPresent('AGT', 7), OSW: bodyDataPresent('AGT', 4), AA: bodyDataPresent('AGT', 3) },
        { name: 'MA', ONW: bodyDataPresent('MA', 3), OSW: bodyDataPresent('MA', 1), AA: bodyDataPresent('MA', 1) },
    ]
    const dataAvailable = [
        { name: 'ONW', 1: bodyDataPresent('ONW', 7), 2: bodyDataPresent('ONW', 4), 3: bodyDataPresent('ONW', 8) },
        { name: 'OSW', 1: bodyDataPresent('OSW', 2), 2: bodyDataPresent('OSW', 2), 3: bodyDataPresent('OSW', 3) },
        { name: 'AA', 1: bodyDataPresent('AA', 1), 2: bodyDataPresent('AA', 2), 3: bodyDataPresent('AA', 4) },
    ]

    const renderColumnsPresent = () => {
        return (headerPresent.map((col) => {
            return <Column key={col.name} field={col.name} headerClassName='operatorView_header' className='operatorView_content' header={headerDataPresent(col.name, col.count)} />;
        }));
    }

    const renderColumnsAvailable = () => {
        return (headerAvailable.map((col) => {
            return <Column key={col.name} field={col.name} headerClassName='operatorView_header' className='operatorView_content' header={headerDataAvailable(col)} />;
        }));
    }

    return (
        <div className="p-p-4" >
            <div className="p-p-4 operatorView p-d-flex p-jc-center p-flex-column p-shadow-12">
                <div className='p-d-flex p-jc-center'>
                    <div className='operatorView_time'>
                        {format(currentTime, "HH:mm:ss")}
                    </div>
                </div>

                <div className='p-p-2 operatorView_evd p-d-flex p-flex-column' >
                    <div className='p-d-flex p-jc-between'>
                        <div>EvD</div>
                        <div className='operatorView_evd_content p-d-flex p-jc-end'>M.Mustermann</div>
                    </div>
                    <div className='p-d-flex  p-jc-between'>
                        <div>{format(currentTime, "'KW' ww")}</div>
                        <div className='operatorView_evd_content p-d-flex  p-flex-column'>
                            <div className='p-d-flex p-jc-end '>0152 2536448</div>
                            <div className='p-d-flex p-jc-end '>0736 972390420394</div>
                        </div>
                    </div>
                </div>

                <div className='operatorView-datatable p-pb-4'> 
                    <DataTable
                        header={<div className="p-d-flex p-jc-center"><div>{'Aktuell anwesend: ' + totalCountPresent}</div></div>}
                        value={dataPresent}
                        className="operatorView-datatable p-datatable-sm">
                        {renderColumnsPresent()}
                    </DataTable>
                </div>

                <div className='operatorView-datatable '>
                    <DataTable
                        className='operatorView-datatable p-datatable-sm'
                        header={<div className="p-d-flex p-jc-center"><div>{'Aktuell verfügbar: ' + totalCountAvailable}</div></div>}
                        value={dataAvailable}>
                        {renderColumnsAvailable()}
                    </DataTable>
                </div>
            </div>
        </div>

    )
}