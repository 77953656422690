import React, { Component } from 'react';
import Login from './login';
import client , {initServiceData} from './feathers';
import { NavigationPage } from './MainPages/NavigationPage';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';


class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {valueInputCaptureActivity:''};
    this.actualizeStateFromFeathers = this.actualizeStateFromFeathers.bind(this);
  }

  actualizeStateFromFeathers = (isNew, servicename, stateVariableName, updatedObject) => {  
    const service = client.service(servicename);
    var values = !this.state[stateVariableName]?[]:this.state[stateVariableName];
    if(isNew === null){
      values = updatedObject;
      this.setState({[stateVariableName]:values});
    } else if(isNew){
      service.get(updatedObject.id).then((result)=>{
        values.unshift(result) 
        this.setState({[stateVariableName]:values});
      })

    } else{
        var foundIndex = values.findIndex(x => x.id === updatedObject.id);
        values[foundIndex] = updatedObject;
        this.setState({[stateVariableName]:values});
    }
    return values.length;
  }


  componentDidMount() {
    // Try to authenticate with the JWT stored in localStorage
    client.authenticate().catch(() => this.setState({ login: null }));

    // On successfull login
    client.on('authenticated', login => {
      this.setState({  login });
      Promise.all([
        initServiceData('time-entry-category','timeEntryCategory',undefined,{})
      ]).then( ([timeEntryCategoryList]) => {
        this.setState({timeEntryCategoryList, login });
        console.log('state:' ,this.state)
      });
      /*Promise.all([
        initServiceData('terminal','terminal',this.actualizeStateFromFeathers,{}),
        initServiceData('users','users',this.actualizeStateFromFeathers,{}),
        initServiceData('employee','employee',this.actualizeStateFromFeathers,params),
        initServiceData('employee-status','employeeStatus',this.actualizeStateFromFeathers,{}),
        initServiceData('tag','tag',this.actualizeStateFromFeathers,params),
        initServiceData('site','site',this.actualizeStateFromFeathers,{}),
        initServiceData('capture-activity','captureActivity',this.actualizeStateFromFeathers,params),
        initServiceData('capture-type','captureType',this.actualizeStateFromFeathers,{}),
        initServiceData('time-entry-category','timeEntryCategory',undefined,{})
      ]).then( ([terminalList,usersList,employeeList,employeeStatusList,tagList,factoryList,captureActivityList,captureTypeList,timeEntryCategoryList]) => {
        this.setState({ terminalList,usersList,employeeList,employeeStatusList,tagList,factoryList,captureActivityList,captureTypeList,timeEntryCategoryList, login });
        console.log('state:' ,this.state)
      });*/
    });

    // On logout reset all all local state (which will then show the login screen)
    client.on('logout', () => this.setState({
      login: null,
      employees: null,
      users: null
    }));
  }
  
  render() {
    if(this.state.login === undefined) {
      return <main className="container text-center">
        <h1>Loading...</h1>
      </main>;
    } else if(this.state.login) {
      return <React.Fragment>
         <NavigationPage user={this.state.login.user} timeEntryCategoryList={this.state.timeEntryCategoryList}/>
      </React.Fragment>
    }

    return <Login />;
  }
}

export default Application;