import io from 'socket.io-client';
import feathers from '@feathersjs/client';

//const socket = io('http://217.160.45.126:80');
const socket = io('https://zs.funkfeuer.info/api');
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(feathers.authentication({
  storage: window.localStorage
}));

export const initServiceData = async (servicename, stateVariablename, callback, params) => {
  const service = client.service(servicename);

  if (callback) {
    // register callback to service
    service.on('created', (createdObject) => callback(true, servicename, stateVariablename + 'List', createdObject));
    service.on('updated', (updatedObject) => callback(false, servicename, stateVariablename + 'List', updatedObject));
  }
  // Get all data from service and call the callback function
  return await service.find(params).then((result) => {
    return result.data ? result.data : result;
  });
}

export const createAdhoc = (employeeId, available) => {
  const adhocService = client.service('adhoc');
  const createdAt = new Date();
  const adhoc = { available, employeeId, createdAt };
  adhocService.create(adhoc);
}

export const createCaptureActivity = (employeeId, tagId, terminalId, captureTypeId) => {
  const captureService = client.service('capture-activity');
  const capture = { "idOnTerminal": 0, "timestamp": Date.now(), tagId, employeeId, terminalId, captureTypeId, "serverCaptureActivityId": 0 };
  captureService.create(capture);
}

export const getTimeEntryForEmployee = async (id, start, end) => {
  const service = client.service('time-entry');
  return await service.find({ 
    query: { 
      employeeId: id, 
      $or:[
        {start: {$and: [{ $gte: start },{ $lte: end }]}},
        {end:{$and: [{ $gte: start },{ $lte: end }]}}
      ]}
    }).then(result => result.data ? result.data : result);
}

export const getTimeEntryCategoryTypeEmployee = async (employee) => {
  const service = client.service('time-entry-category-type-employee');
  return (employee ? await service.find({ query: { employeeId: employee.id } }).then(result => result.data ? result.data : result) : []);
}

export const getAdhocEmployee = async (id) => {
  const service = client.service('adhoc');
  return await service.find({ query: { employeeId: id } }).then(result => result.data ? result.data : result);
}

export const updateTimeEntry = async (timeEntry) => {
  const service = client.service('time-entry');
  const { id, employeeId, title, start, end } = timeEntry
  const updateTimeEntry = {
    id, employeeId, title, start, end,
    timeEntryCategoryId: timeEntry.timeEntryCategory.id,
    timeEntryCategoryTypeEmployeeId: timeEntry.timeEntryCategoryType ? timeEntry.timeEntryCategoryType.id : null,
    secondsToArrive: timeEntry.timeEntryCategoryType ? timeEntry.timeEntryCategoryType.secondsToArrive : null,
  }
  if (!timeEntry.id) {
    console.log("create TimeEntry:", updateTimeEntry);
    return await service.create(updateTimeEntry);
  } else {
    console.log("update TimeEntry:", updateTimeEntry);
    return await service.update(timeEntry.id, updateTimeEntry);
  }
}

export const getData = async (path) => {
  const service = client.service(path);
  const list = await service.find().then((result) => {
    return result.data ? result.data : result;
  });
  return list;
}

export const updateData = async (path, data) => {
  const service = client.service(path);
  if(!data.id){
    return await service.create(data).catch((error) => {
      console.error("ERROR updateData:" + error);
    });;
  } else {
    return await service.update(data.id, data).catch((error) => {
      console.error("ERROR updateData:" + error);
    });;
  }
}

export default client;
