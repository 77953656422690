import { CalendarEmployee } from '../Calendar/CalendarEmployee';
import { EmployeeStatus } from '../Employee/EmployeeStatus';


export const EmployeePage = ({ screenHeight, timeEntryCategoryList, user }) => {

    console.log("EmployeePage",screenHeight);

    return (
        <div className="p-grid" style={{ height: (screenHeight - 150) + 'px' ,backgroundColor: 'rgb(255,255,255,0.2)' }}>
            <div className="p-col-fixed" style={{ width: '400px' }}>
                <EmployeeStatus employee={user.employee} />
            </div>
            <div className="p-col" >
                <CalendarEmployee employee={user.employee} timeEntryCategoryList={timeEntryCategoryList} />
            </div>
        </div>
    )
}