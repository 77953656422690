import React , {useRef, useState, useEffect }from 'react'
import { Menu } from 'primereact/menu';
import { PanelMenu  } from 'primereact/panelmenu';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import client from '../feathers';
import { useMediaQuery } from '../Utils/useMediaQuery'
import { setSyntheticTrailingComments } from 'typescript';

export const Sidemenu = ({user, visibleMenu, setVisibleMenu, handleSidemenuCommand})=>{
    const menu = useRef(null);
    const toast = useRef(null);
    const [screenSize] = useMediaQuery();
    const [items, setItems] = useState();

    useEffect(()=>{
        const items = [];
        if (user.employee) {
            items.push(
                {
                    label: 'Mein Bereich', icon: 'pi pi-home',
                    command: () => {
                        toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                        handleSidemenuCommand('EmployeePage');
                    }
                }
            )
            items.push({separator: true})
        }    
        if (user.roles.find(e => e.name === 'operatorView')) {
            items.push({label: 'Kräfteübersicht', icon: 'pi pi-lock', command: () => {handleSidemenuCommand('OperatorView')}});
            items.push({separator: true});
        }
        if (user.roles.find(e => e.name === 'HR')) {
            items.push({label: 'Stammdaten', icon: 'pi pi-users', command: () => { handleSidemenuCommand('EmployeesPage')}});
            items.push({separator: true});
        }
        if (user.roles.find(e => e.name === 'HR')) {
            items.push({label: 'Auswertungen', icon: 'pi pi-chart-bar', items: [{label: 'Alarm & Übung',command:(e) => { handleSidemenuCommand('OperationReport')}}]});
            items.push({separator: true});
        }
        if (user.roles.find(e => e.name === 'HR')) {
            const subItems = [ {
                label: 'Standorte',
                command:(e) => { handleSidemenuCommand('ManageSites')}
            },
            {
                label: 'Org. Funktionen',
                command:(e) => { handleSidemenuCommand('ManageOrganizationFunctions')}
            },
            {
                label: 'Qual. Funktionen',
                command:(e) => { handleSidemenuCommand('ManageQualificationFunctions')}
            },
            {
                label: 'Unternehmensbereiche',
                command:(e) => { handleSidemenuCommand('ManageBusinessUnits')}
            }]
            items.push({label: 'Konfiguration', icon: 'pi pi-cog', items:subItems});
        }
        setItems(items)
    },[user])


    const itemsFooter = [
        {
            label: 'ausloggen',
            icon: 'pi pi-sign-out',
            command:(e) => { client.logout() }
        }
    ]

    return(
        <>
            <Toast ref={toast}></Toast>
           
            <Sidebar visible={visibleMenu} onHide={() => setVisibleMenu(false)}  >
                <div className="p-d-flex p-mt-3 p-flex-column p-jc-between" style={{height:screenSize[1]-100}}>
                    <div className='p-shadow-24' style={{ backgroundColor: 'rgb(0,123,0,0.2)', width: '250px' }}>
                        <Menu model={items} style={{width:'250px'}}/>
                    </div>
                    <div className="p-mr-2 p-as-start">
                        <PanelMenu  model={itemsFooter} style={{width:'250px'}}/>                       
                    </div>
                </div>
            </Sidebar>
        </>
    )
}

