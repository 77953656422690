import { useState , useRef, useEffect} from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { OperationReportDialog } from './OperationReportDialog';
import { Button } from 'primereact/button';
import './OperationReport.scss';

export const OperationReport = ({ }) => {

    const [selectedItem, setSelectedItem] = useState(null);
    const op = useRef(null);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            op.current.hide();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isMounted.current = true;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const items = [
        { id: 1, name: 'Tester', start: '25.06. 12:38', end: '25.06. 16:39', before: true, after: false, isService: true },
        { id: 2, name: 'Donald', start: '25.06. 13:01', end: '25.06. 17:38', before: false, after: false, isService: false },
        { id: 3, name: 'Daisy', start: '25.06. 12:36', end: '25.06. 14:57', before: true, after: true, isService: true },
        { id: 4, name: 'Roadrunner', start: '25.06. 12:36', end: '25.06. 14:57', before: false, after: true, isService: true },
    ]


    return (
        <>
            <DataTable
                id='operationreport'
                className='operationreport p-datatable-sm'
                header={<Button type="button" icon="pi pi-plus" label='Neuer Report' onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="operationreport_overlay_panel" />}
                scrollable scrollHeight="400px"
                value={items}
                selection={selectedItem}
                onSelectionChange={(e) => setSelectedItem(e.value)}
                dataKey="id">
                <Column selectionMode="single" headerStyle={{ width: '30px' }}></Column>
                <Column header="Start" field='start' ></Column>
                <Column header="Ende" field='end'></Column>
            </DataTable>
            <OverlayPanel ref={op} dismissable={false} id="operationreport_overlay_panel" style={{ width: '1500px' }}>
                <OperationReportDialog/>
            </OverlayPanel>
        </>
    )
}