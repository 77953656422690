import { useState, useEffect} from 'react';
import {InputText} from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { RadioButton } from 'primereact/radiobutton';


export const CalendarTimeEntryPanel = ({display, setDisplay, timeEntry, handleUpdateTimeEntry}) =>{
    const [title, setTitle] = useState(timeEntry.title);
    const [start,setStart] = useState(timeEntry.start);
    const [end,setEnd] = useState(timeEntry.end);
    const [timeEntryCategory,setTimeEntryCategory] = useState(timeEntry.timeEntryCategory);
    const [timeEntryCategoryType,setTimeEntryCategoryType] = useState(timeEntry.timeEntryCategoryTypeEmployee);

    /*useEffect(() => {
        setTitle(timeEntry.title);
        setStart(timeEntry.start);
        setEnd(timeEntry.end);
        setTimeEntryCategory(timeEntry.timeEntryCategory);
        setTimeEntryCategoryType(timeEntry.timeEntryCategoryTypeEmployee);
    }, [])*/

    const renderTimeEntryCategory = () =>{
        const timeEntryCategoryList = (!timeEntry?[]:timeEntry.timeEntryCategories);
        console.log("renderTimeEntryCategory:",timeEntryCategoryList)
        return timeEntryCategoryList.map((category) => {          
            return (
                <div key={category.id} className="p-field-radiobutton">
                    <RadioButton inputId={'' + category.id} name="category" value={category} onChange={handleChangeTimeEntryCategory}  checked={timeEntryCategory.id === category.id} />
                    <label htmlFor={category.id}>{category.name + ' (' + category.timeEntryCategoryType.name + ')'}</label>
                </div>
            )
        });
    }

    const renderTimeEntryCategoryType = () =>{
        console.log("renderTimeEntryCategoryType:",timeEntryCategory.timeEntryCatagoryTypeEmployee)
        if(!timeEntryCategory.timeEntryCatagoryTypeEmployee){ return ''} 
        return timeEntryCategory.timeEntryCatagoryTypeEmployee.map((categoryType) => {          
            return (
                <div key={categoryType.id} className="p-field-radiobutton">
                    <RadioButton inputId={'' + categoryType.id} name="categoryType" value={categoryType} onChange={handleChangeTimeEntryCategoryType}  checked={timeEntryCategoryType.id === categoryType.id} />
                    <label htmlFor={categoryType.id}>{(!categoryType.site?categoryType.name:'Werk: ' + categoryType.site.alias) + ' -> Ankunft: ' + (categoryType.secondsToArrive/60) + ' min'}</label>
                </div>
            )
        });
    }
    const handleChangeTimeEntryCategoryType = (e) =>{
        setTimeEntryCategoryType( e.value);
    }

    const handleChangeTimeEntryCategory = (e) =>{
        setTimeEntryCategory( e.value);
        if(!timeEntryCategoryType || timeEntryCategoryType.timeEntryCategoryTypeId !==  e.value.timeEntryCategoryTypeId){
            setTimeEntryCategoryType( e.value.timeEntryCatagoryTypeEmployee[0]);
        }
    }

    const cancelTimeEntry = () =>{
        setDisplay(false);
    }

    const saveTimeEntry = () =>{
        console.log("save Time entry");
        const updatedtimeEntry = {id:timeEntry.id,employeeId:timeEntry.employeeId, title, start,end,timeEntryCategory:timeEntryCategory,timeEntryCategoryType:timeEntryCategoryType}
        handleUpdateTimeEntry(updatedtimeEntry);
    }

    const footer = (
        <div>
            <Button label="Save" icon="pi pi-check" onClick={() => saveTimeEntry(timeEntry)}/>
            <Button label="Delete" icon="pi pi-trash" />
            <Button label="Cancel" icon="pi pi-times" onClick={cancelTimeEntry}/>
        </div>
    );
    return (
        <Dialog footer={footer} header="Zeiteintrag" visible={display} style={{fontSize:'12pt',width:'700px'}} onHide={() => setDisplay(false)}>
            <div className="p-grid nested-grid">
                <div className="p-col-5">
                    <div className="p-grid">
                        <label htmlFor="titel" className="p-col-fixed" style={{width:'50px'}}>Titel</label>
                        <div className="p-col">
                            <InputText id="timeEntryTitel" type="text" value={title} onChange={(e)=>setTitle(e.target.value)}/>
                        </div>
                    </div>
                    <div className="p-grid">
                        <label htmlFor="start" className="p-col-fixed" style={{width:'50px'}}>Start</label>
                        <div className="p-col">
                            <Calendar showTime hourFormat='24' dateFormat="dd.mm.yy" value={start} onChange={(e) => setStart(e.value)}  />
                        </div>
                    </div>
                    <div className="p-grid">
                        <label htmlFor="end" className="p-col-fixed" style={{width:'50px'}}>Ende</label>
                        <div className="p-col">
                        <Calendar showTime hourFormat='24' dateFormat="dd.mm.yy" value={end} onChange={(e) => setEnd(e.value)}  />
                        </div>
                    </div>
                    <Divider type="dashed" />
                    <div className="p-grid">
                        <div className="p-col">
                          {renderTimeEntryCategoryType()}
                        </div>
                    </div>
                </div>
                <div className="p-col-5"> {renderTimeEntryCategory()} </div>
            </div>
        </Dialog>
    )

}