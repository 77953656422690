import React, { useState, useEffect } from 'react';
import { getData, updateData } from '../feathers';
import { EmployeeForm } from './EmployeeForm';
import { EmployeeList } from './EmployeeList';
import { EmployeeStatus } from './EmployeeStatus'
import { CalendarPage } from '../Calendar/CalendarPage'
import { CalendarEmployee } from '../Calendar/CalendarEmployee'
import { TabView, TabPanel } from 'primereact/tabview';
import { EmployeeCarousel } from './EmployeeCarousel'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const EmployeesPage = ({ timeEntryCategoryList }) => {
    const emptyEmployee = {
        id: null, lastname: '', firstname: ''
    }

    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(emptyEmployee);
    const [editMode, setEditMode] = useState(false);
    const [businessUnitList, setBusinessUnitList] = useState();
    const [siteList, setSiteList] = useState();
    const [organizationFunctionList, setOrganizationFunctionList] = useState();
    const [qualificationFunctionList, setQualificationFunctionList] = useState();

    useEffect(() => {
        async function setDefaultValues() {
            setBusinessUnitList(await getData('business-unit'));
            setSiteList(await getData('site'));
            setOrganizationFunctionList(await getData('organization-function'));
            setQualificationFunctionList(await getData('qualification-function'));
        }
        setDefaultValues();
    }, [])

    const handleChangeSelectedEmployee = (employee) => {
        console.log('handleChangeSelectedEmployee', employee);
        if (employee != null) {
            setSelectedEmployee({ ...employee });
            setEditMode(false);
        }

    }

    const handleEditMode = () => {
        if (editMode) {
            handleChangeSelectedEmployee(employeeList.find((e) => e.id === selectedEmployee.id));
        } else {
            setEditMode(true)
        }

    }

    useEffect(() => {
        async function init() {
            setEmployeeList(await getData('employee'));
        }
        init();
    }, [])

    const handleSaveEmployee = async (employee) => {
        setEditMode(false);
        await updateData('employee', employee);
        setEmployeeList(await getData('employee'));
    }

    const renderEmployeeForm = () => {
        return <TabPanel id='nav-page-tabview' header='Stammdaten'>
            <EmployeeForm
                employee={selectedEmployee}
                siteList={siteList}
                businessUnitList={businessUnitList}
                organizationFunctionList={organizationFunctionList}
                qualificationFunctionList={qualificationFunctionList}
                handleSaveEmployee={handleSaveEmployee}
                editMode={editMode}
                handleEditMode={handleEditMode} />
        </TabPanel>
    }

    const renderEmployeeStatus = () =>{
        return <TabPanel id='nav-page-tabview' header='Status'>
                <EmployeeStatus employee={selectedEmployee} />
            </TabPanel>
    }

    const renderEmployeeCalendar = () =>{
        return <TabPanel id='nav-page-tabview' header='Kalender'>
                <CalendarEmployee employee={selectedEmployee} timeEntryCategoryList={timeEntryCategoryList} />
            </TabPanel>
    }


    return (
        <React.StrictMode>
            <div className="p-grid p-m-4" >
                <div className="p-col-3" >
                    <EmployeeList employeeList={employeeList} selectedEmployee={selectedEmployee} handleChangeSelectedEmployee={handleChangeSelectedEmployee} />
                </div>
                <div className="p-col-9" >
                    <TabView id='nav-page-tabview'>
                        {renderEmployeeForm()}
                        {renderEmployeeCalendar()}
                        {renderEmployeeStatus()}
                    </TabView>
                </div>
            </div>
        </React.StrictMode>
    );


}