import { useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns'
import './OperationReport.scss'

export const OperationReportDialog = ({ }) => {


    const [selectedItems, setSelectedItems] = useState(null);
    const [startTime, setStartTime] = useState(new Date())
    const [endTime, setEndTime] = useState(new Date())

    const items = [
        { id: 1, name: 'Tester', start: '25.06. 12:38', end: '25.06. 16:39', before: true, after: false, isService: true },
        { id: 2, name: 'Donald', start: '25.06. 13:01', end: '25.06. 17:38', before: false, after: false, isService: false },
        { id: 3, name: 'Daisy', start: '25.06. 12:36', end: '25.06. 14:57', before: true, after: true, isService: true },
        { id: 4, name: 'Roadrunner', start: '25.06. 12:36', end: '25.06. 14:57', before: false, after: true, isService: true },
    ]

    const beforeBodyTemplate = (rowData) => {
        const style = (rowData.before ? { width: '40px', padding: '0', backgroundColor: 'rgba(255,255,255,0.3)' } : { width: '40px' })
        return (
            <div className="p-grid" style={{ margin: '0px', width: '100%', padding: '0' }}>
                <div className="p-col" style={{ padding: '0' }}>{!rowData.before ? '' : rowData.start}</div>
                <div className="p-col-fixed" style={style} />
            </div>

        );
    }
    const afterBodyTemplate = (rowData) => {
        const style = (rowData.after ? { width: '40px', padding: '0', backgroundColor: 'rgba(255,255,255,0.3)' } : { width: '40px' })
        return (
            <div className="p-grid" style={{ margin: '0px', width: '100%', padding: '0' }}>
                <div className="p-col-fixed" style={style} />
                <div className="p-col" style={{ padding: '0', marginLeft: '10px' }} >{!rowData.after ? '' : rowData.end}</div>
            </div>
        );
    }
    const bodyTemplate = (rowData) => {
        const style = (rowData.after ? { width: '200px', padding: '0', backgroundColor: 'rgba(255,255,255,0.3)' } : { width: '200px' })
        if (rowData.before && rowData.after) {
            return (
                <div className="p-grid" style={{ margin: '0px', width: '100%', padding: '0' }}>
                    <div className="p-d-flex p-jc-center" style={{ width: '100%', backgroundColor: 'rgba(255,255,255,0.3)' }} >
                        {rowData.isService ? 'Dienst' : ''}
                    </div>
                </div>
            );
        } else if (!rowData.before && !rowData.after) {
            return (
                <div className="p-grid" style={{ margin: '0px', width: '100%', padding: '0' }}>
                    <div className="p-d-flex p-jc-end" style={{ width: '100px' }}>
                        {rowData.start}
                    </div>
                    <div className="p-d-flex p-jc-center" style={{ margin: '0 4px', width: '240px', backgroundColor: 'rgba(255,255,255,0.3)' }} >
                        {rowData.isService ? 'Dienst' : ''}
                    </div>
                    <div className="p-d-flex" style={{ width: '100px' }}>
                        {rowData.end}
                    </div>
                </div>
            );
        } else if (rowData.before) {
            return (
                <div className="p-grid" style={{ margin: '0px', width: '100%', padding: '0' }}>
                    <div className="p-d-flex p-jc-center" style={{ margin: '0 4px 0 0', width: '340px', backgroundColor: 'rgba(255,255,255,0.3)' }} >
                        {rowData.isService ? 'Dienst' : ''}
                    </div>
                    <div className="p-d-flex" style={{ width: '100px' }}>
                        {rowData.end}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="p-grid" style={{ margin: '0px', width: '100%', padding: '0' }}>
                    <div className="p-d-flex p-jc-end" style={{ width: '100px' }}>
                        {rowData.start}
                    </div>
                    <div className="p-d-flex p-jc-center" style={{ margin: '0 0 0 4px', width: '340px', backgroundColor: 'rgba(255,255,255,0.3)' }} >
                        {rowData.isService ? 'Dienst' : ''}
                    </div>
                </div>
            );
        }

    }

    const renderHeader = () => {
        return (
            <div>
                <div className="p-d-flex p-jc-center">Neuer Report</div>
                
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-6">
                            <label htmlFor="subject">Titel</label>
                            <InputText id="subject" type="text" placeholder="Titel" />
                        </div>
                        <div className="p-field p-col-3">
                            <label htmlFor="startTime">Start</label>
                            <Calendar id="startTime" showTime dateFormat="dd.mm.yy" value={startTime} onChange={(e) => setStartTime(e.target.value)} showIcon />
                        </div>
                        <div className="p-field p-col-3" >
                            <label htmlFor="endTime">Ende</label>
                            <Calendar id="endTime" showTime dateFormat="dd.mm.yy" value={endTime} onChange={(e) => setEndTime(e.target.value)} showIcon />
                        </div>

                    </div>
                </div>

        )
    }

    const renderStartTime = () =>{
        return(<b>Start {format(startTime,'dd.MM HH:mm')}</b>)
    }
    const renderEndTime = () =>{
        return(<b>Ende {format(endTime,'dd.MM HH:mm')}</b>)
    }
    return (
        <div className='p-shadow-12'>
            <DataTable
                id='operationreport'
                className='operationreport p-datatable-sm'
                header={renderHeader()}
                scrollable scrollHeight="400px"
                value={items}
                selection={selectedItems}
                onSelectionChange={(e) => setSelectedItems(e.value)}
                dataKey="id">
                <Column selectionMode="multi" headerStyle={{ width: '30px' }}></Column>
                <Column field="name" headerStyle={{ width: '200px' }}></Column>
                <Column header='' headerStyle={{ width: '150px' }} body={beforeBodyTemplate} />
                <Column header={renderStartTime()} headerStyle={{ borderWidth: '0 0 0 0', width: '1px', padding: '0px' }} style={{ borderWidth: '0 2px 0 0', borderColor: 'red' }} />
                <Column header={<b></b>} headerStyle={{ width: '100%', align: 'right', width: '450px' }} style={{ padding: '0px' }} body={bodyTemplate} />
                <Column header={renderEndTime()} headerStyle={{ borderWidth: '0 0 0 0', width: '1px', padding: '0px' }} style={{ borderWidth: '0 2px 0 0', borderColor: 'red' }} />
                <Column headerStyle={{ width: '250px' }} body={afterBodyTemplate} />
                <Column header="" ></Column>
            </DataTable>
        </div>
    )
}