import React, { useState, useEffect } from 'react';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { useMediaQuery } from '../Utils/useMediaQuery'
import './EmployeeForm.scss'

export const EmployeeForm = ({employee, editMode, siteList, businessUnitList, organizationFunctionList, qualificationFunctionList, handleSaveEmployee, handleEditMode}) => {

    const [lastname, setLastname] = useState();
    const [firstname, setFirstname] = useState();
    const [personalNumber, setPersonalNumber] = useState();
    const [internetAddress, setInternetAddress] = useState();
    const [phone, setPhone] = useState();
    const [mobile, setMobile] = useState();
    const [G26Date, setG26Date] = useState();
    const [drivingLicenseDate, setDrivingLicenseDate] = useState();
    const [siteId, setSiteId] = useState();
    const [businessUnitId, setBusinessUnitId] = useState();
    const [organizationFunctionIds, setOrganizationFunctionIds] = useState();
    const [qualificationFunctionIds, setQualificationFunctionIds] = useState();
    const [secordsToArriveSite,setSecordsToArriveSite] = useState();
    const [secordsToArriveHome,setSecordsToArriveHome] = useState();
    const [screenSize] = useMediaQuery();

    useEffect(() => {
        setLastname(employee.lastname?employee.lastname:"");
        setFirstname(employee.firstname?employee.firstname:"");
        setPersonalNumber(employee.personalNumber?employee.personalNumber:"");
        setInternetAddress(employee.internetAddress?employee.internetAddress:"");
        setPhone(employee.phone?employee.phone:"");
        setMobile(employee.mobile?employee.mobile:"");
        setG26Date(employee.G26Date?new Date(employee.G26Date):"");
        setDrivingLicenseDate(employee.drivingLicenseDate?new Date(employee.drivingLicenseDate):"");
        setSiteId(employee.siteId?employee.siteId:"");
        setBusinessUnitId(employee.businessUnitId?employee.businessUnitId:"");
        const orgFunctions = (employee.organization_functions ? employee.organization_functions : [])
        setOrganizationFunctionIds(orgFunctions.map((orgFunction) => orgFunction.id));
        const qualFunctions = (employee.qualification_functions ? employee.qualification_functions : [])
        setQualificationFunctionIds(qualFunctions.map((qualFunction) => qualFunction.id));
        var {time_entry_category_type_employees} = employee;
        time_entry_category_type_employees= (time_entry_category_type_employees?time_entry_category_type_employees:[]);
        const categoryFacory = time_entry_category_type_employees.find((e)=>e.timeEntryCategoryTypeId === 1)
        setSecordsToArriveSite(categoryFacory?categoryFacory.secondsToArrive:0);
        const categoryHome = time_entry_category_type_employees.find((e)=>e.timeEntryCategoryTypeId === 2)
        setSecordsToArriveHome(categoryHome?categoryHome.secondsToArrive:0);
        console.log("useEffect",employee.mobile)
    }, [employee,editMode])

    const onHandleCancelEmployee = () =>{
        handleEditMode();
    }

    const onHandleSaveEmployee = () => {
        const tempOrganizationFunctions = organizationFunctionIds.map((orgFunction) => organizationFunctionList.find((of) => of.id === orgFunction));
        const tempQualificationFunctions = qualificationFunctionIds.map((qualFunction) => qualificationFunctionList.find((qf) => qf.id === qualFunction));
        const updateEmployee = {
            id: employee.id,
            personalNumber,
            lastname,
            firstname,
            siteId,
            businessUnitId,
            internetAddress,
            phone,
            mobile,
            G26Date,
            drivingLicenseDate,
            organization_functions: tempOrganizationFunctions,
            qualification_functions: tempQualificationFunctions
        };
        console.log('updateEmployee', updateEmployee);
        handleSaveEmployee(updateEmployee);
    }

    return (
        <div id='employeeform_maindiv' style={{height:(screenSize[1]-150) + 'px'}}>
            <div className="p-grid">
                <div className="p-col">
                    <div className="p-field p-grid">
                        <label htmlFor="personalNumber" className="p-col-fixed p-sm" style={{ width: '200px' }}>Personalnummer</label>
                        <InputText disabled={!editMode} className="p-col p-inputtext-sm" id="personalNumber" type="text" value={personalNumber} onChange={(e) => setPersonalNumber(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="firstname" className="p-col-fixed" style={{ width: '200px' }}>Vorname</label>
                        <InputText disabled={!editMode} className="p-col p-inputtext-sm" id="firstname" type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="lastname" className="p-col-fixed" style={{ width: '200px' }}>Nachname</label>
                        <InputText disabled={!editMode} className="p-col p-inputtext-sm" id="lastname" type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="siteId" className="p-col-fixed" style={{ width: '200px' }}>Werk</label>
                        <Dropdown disabled={!editMode} className="p-col p-inputtext-sm" optionLabel="name" optionValue="id" options={siteList} name="siteId" value={siteId} id="facoryId" onChange={(e) => setSiteId(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="businessUnitId" className="p-col-fixed" style={{ width: '200px' }}>Unternehmensbereich</label>
                        <Dropdown disabled={!editMode} className="p-col p-inputtext-sm" optionLabel="name" optionValue="id" options={businessUnitList} name="businessUnitId" value={businessUnitId} id="businessUnitId" onChange={(e) => setBusinessUnitId(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="phone" className="p-col-fixed" style={{ width: '200px' }}>Telefon</label>
                        <InputText disabled={!editMode} className="p-col p-inputtext-sm" id="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="mobile" className="p-col-fixed" style={{ width: '200px' }}>Mobil</label>
                        <InputText disabled={!editMode} className="p-col p-inputtext-sm" id="mobile" type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="internetAddress" className="p-col-fixed" style={{ width: '200px' }}>E-Mail</label>
                        <InputText disabled={!editMode} className="p-col p-inputtext-sm" id="internetAddress" type="text" value={internetAddress} onChange={(e) => setInternetAddress(e.target.value)} />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="G26Date" className="p-col-fixed" style={{ width: '200px' }}>G26-Datum</label>
                        <Calendar disabled={!editMode} id="icon" dateFormat="dd.mm.yy" value={G26Date} onChange={(e) => setG26Date(e.target.value)} showIcon />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="drivingLicenseDate" className="p-col-fixed" style={{ width: '200px' }}>Führerschein Datum</label>
                        <Calendar disabled={!editMode} id="icon" dateFormat="dd.mm.yy" value={drivingLicenseDate} onChange={(e) => setDrivingLicenseDate(e.target.value)} showIcon />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="secordsToArriveSite"  className="p-col-fixed" style={{ width: '200px' }}>Zeit von Werk</label>
                        <InputNumber disabled={!editMode} className="p-col p-inputtext-sm" id="secordsToArriveSite" value={secordsToArriveSite} onValueChange={(e) => setSecordsToArriveSite(e.value)} suffix=" Sekunden" />
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="secordsToArriveHome"  className="p-col-fixed" style={{ width: '200px' }}>Zeit von Home</label>
                        <InputNumber disabled={!editMode} className="p-col p-inputtext-sm" id="secordsToArriveHome" value={secordsToArriveHome} onValueChange={(e) => setSecordsToArriveHome(e.value)} suffix=" Sekunden" />
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-field p-grid  p-align-start">
                        <label htmlFor="organizationFunctionIds" className="p-col-fixed" style={{ width: '150px' }}>Org-Funktionen</label>
                        <ListBox style={{fontSize:'10pt',padding:'0px'}} disabled={!editMode} className="p-col p-inputtext-sm" multiple optionLabel="name" optionValue="id" options={organizationFunctionList} name="organizationFunctionIds" value={organizationFunctionIds} id="organizationFunctionIds" onChange={(e) => setOrganizationFunctionIds(e.target.value)} />
                    </div>
                    <div className="p-field p-grid  p-align-start">
                        <label htmlFor="qualificationFunctionIds" className="p-col-fixed" style={{ width: '150px' }}>Qualifikationen</label>
                        <ListBox style={{fontSize:'10pt',padding:'0px'}} disabled={!editMode} className="p-col p-inputtext-sm" multiple optionLabel="name" optionValue="id" options={qualificationFunctionList} name="qualificationFunctionIds" value={qualificationFunctionIds} id="qualificationFunctionIds" onChange={(e) => setQualificationFunctionIds(e.target.value)} />
                    </div>
                </div>

            </div>
            <div className="p-field p-grid">
                <Button className='p-button-secondary' icon={!editMode ?'pi pi-pencil':'pi pi-times'} iconPos="right" onClick={onHandleCancelEmployee} label={(!editMode ? 'Mitarbeiter bearbeiten' : 'Bearbeitung abbrechen')} />
                <Button className={!editMode ? 'p-d-md-none' : 'p-button-success p-ml-2'} icon="pi pi-check" iconPos="right" onClick={onHandleSaveEmployee} label="Mitarbeiter speichern" />
            </div>
        </div>
    );
}