import React,  {useRef, useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useMediaQuery } from '../Utils/useMediaQuery'
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';



export const EmployeeList =  ({ employeeList,selectedEmployee, handleChangeSelectedEmployee}) =>{
    const [screenSize] = useMediaQuery();
    console.log('EmployeeList:',selectedEmployee);

    return(
        <>
            <div >
                   <DataTable style={{overflow:'auto' }} scrollable scrollHeight={(screenSize[1]-200) +'px'}   value={employeeList} selection={selectedEmployee} onSelectionChange={(e) => {handleChangeSelectedEmployee(e.value)}} dataKey="id" className="p-datatable-sm">
                        <Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
                        <Column field="lastname" header="Nachname" filter filterPlaceholder="Search by name" ></Column>
                        <Column field="firstname" header="Vorname"></Column>
                    </DataTable>
            </div>

        </>
    );

}