import React, { useState, useEffect } from 'react';
import { FullCalendar } from 'primereact/fullcalendar';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin  from '@fullcalendar/timegrid';
import interactionPlugin  from '@fullcalendar/interaction';
import { addHours } from 'date-fns';
import { getTimeEntryForEmployee, getTimeEntryCategoryTypeEmployee, updateTimeEntry} from '../feathers';
import { CalendarTimeEntryPanel } from './CalendarTimeEntryPanel';

var calendarRef = React.createRef();

export const CalendarEmployee = ({screenHeight, employee,timeEntryCategoryList}) =>{
    const [ timeEntry,setTimeEntry] = useState();
    const [ display,setDisplay] = useState(false);
    const [ refresh,setRefresh] = useState(false);

    const getTimeEntryCategories = async () => {
        return await getTimeEntryCategoryTypeEmployee(employee).then(async(json)=>{
            var categoryTypeFactory = [];
            var categoryTypeHome = [];
            /** Beide Arrays categoryTypeFactory und  categoryTypeHome mit Daten des Employee befüllen*/
            await json.forEach((categoryType) => {categoryType.timeEntryCategoryTypeId === 1?categoryTypeFactory.push(categoryType):categoryTypeHome.push(categoryType)}); 

            /** Liste der timeEntryCatergory durchlaufen und mit den möglichen timeEntryCategoryType des employee ergänzen */
            const timeEntryCategoryEmployeeList = await timeEntryCategoryList.map((timeEntryCategory) =>{
                if(timeEntryCategory.timeEntryCategoryTypeId===1){
                    return timeEntryCategory = {...timeEntryCategory,timeEntryCatagoryTypeEmployee:categoryTypeFactory}
                }else if(timeEntryCategory.timeEntryCategoryTypeId===2){
                    return timeEntryCategory = {...timeEntryCategory,timeEntryCatagoryTypeEmployee:categoryTypeHome}
                }else{
                    return timeEntryCategory = {...timeEntryCategory,timeEntryCatagoryTypeEmployee:[]}
                }
            });
            /** ------------------------------------------------------------------------------------------------------------ */
            return timeEntryCategoryEmployeeList;
        });
    }

    var oneClickTimer;
    let clickCnt = 0;

    const options = {
        height: '700px',
        contentHeight: '700px',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultView: 'timeGridWeek', initialView: 'timeGridWeek',

        dateClick: async (info) =>  {
            clickCnt++;         
            if (clickCnt === 1) {
                oneClickTimer = setTimeout(() => clickCnt = 0, 400);
            } else if (clickCnt === 2) {
                clearTimeout(oneClickTimer);
                clickCnt = 0;
                var currentDate = new Date(info.date);
                const timeEntryCategories = await getTimeEntryCategories();
                var newTimeEntry = {timeEntryCategories,
                    title:'new Timeentry',
                    employeeId:employee.id,
                    timeEntryCategory: timeEntryCategories[0],
                    timeEntryCategoryTypeEmployee: timeEntryCategories[0].timeEntryCatagoryTypeEmployee[0],
                    start:currentDate,
                    end:addHours(currentDate,8),
                }
                setTimeEntry(newTimeEntry)
                setDisplay(true);
            }          
        },

        eventClick: async (info) =>  {
            //info.el.style.backgroundColor = 'red';
            //console.log("event:",info.event)
 
            clickCnt++;         
            if (clickCnt === 1) {
                oneClickTimer = setTimeout(() => clickCnt = 0, 400);
            } else if (clickCnt === 2) {
                clearTimeout(oneClickTimer);
                clickCnt = 0;
                const timeEntryCategories = await getTimeEntryCategories();
                //console.log('timeEntryCategories click: ' ,timeEntryCategories);
                setTimeEntry({timeEntryCategories,
                    employeeId:employee.id,
                    id:info.event.id,
                    title:info.event.title,
                    end:info.event.end,
                    start:info.event.start,
                    timeEntryCategory:timeEntryCategories.find(tec => tec.id === info.event.extendedProps.timeEntryCategory.id),
                    timeEntryCategoryTypeEmployee:info.event.extendedProps.timeEntryCategoryTypeEmployee,
                })
                setDisplay(true);
            }  
        },

        eventChange: (info) =>  {
            //console.log("eventChange:",info);
        },

        select: (info) => {
            //console.log("select:" + info.startStr + " - " + info.endStr,info);
        },

        customButtons: {
            myCustomButton: {
              text: 'früh',
              click: function() {
                alert('clicked the custom Frühschicht');
              }
            }
          },
        /*footerToolbar: {
            center: 'myCustomButton' // buttons for switching between views
        },*/

        locales: [ deLocale ],
        weekNumbers:true,
        slotDuration:'01:00:00',
        slotLabelInterval:"01:00:00",
        dayHeaderFormat:{ weekday: 'short', day: 'numeric', omitCommas: true },
        slotLabelFormat: [{ hour: 'numeric' }],
        allDaySlot:true,
        slotEventOverlap:false,
        selectable: true,
        // header: {
        //     left: 'prev,next',
        //     center: 'title',
        //     right: 'dayGridMonth,timeGridWeek,timeGridDay'
        // },
        headerToolbar: {
            left: 'title',
            right: 'prev,next,today',
            center: 'dayGridMonth,timeGridWeek,timeGridDay' // buttons for switching between views
        },
        views: {           
            timeGridFourDay: {
              type: 'timeGrid',
              duration: { days: 6 },
              buttonText: '6 day'
            }
         },
        editable: false,
        navLinks: true,
        events: function (info, successCallback, failureCallback) { 
            getTimeEntries(info).then((events) =>{
                //console.log('events:',events);
                successCallback(events);
            }).catch((error) => {
                failureCallback(error);
            });
           
        },
    };

    const handleUpdateTimeEntry = async (timeEntry) =>{
        await updateTimeEntry(timeEntry).then().catch((error) => {
            console.error("ERROR handleUpdateTimeEntry:" + error, error);
          });
        setDisplay(false);
    }
    
    const getTimeEntries =  async (info) =>{
        if(!employee){
           return [];
        }
        const events =  await getTimeEntryForEmployee(employee.id,info.start,info.end).then((json)=>{
            console.log("timeEntries: " + employee.id,json.length)
            return json.map((timeEntry)=>{
                const backgroundColor = timeEntry.timeEntryCategory.available?'green':'red';
                return {...timeEntry,backgroundColor} 
            })
        });
        
        return events;
    }

    useEffect(()=>{
        setRefresh(true);
    },[])


    return (
        <React.Fragment>
            {!display?'': <CalendarTimeEntryPanel  timeEntry={timeEntry} display={display} setDisplay={setDisplay} handleUpdateTimeEntry={handleUpdateTimeEntry}/> }  
            <FullCalendar options={options}  ref={calendarRef} style={{width:'1000px'}}/>    
        </React.Fragment>
    );
}