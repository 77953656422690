import React, { useState ,useEffect} from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { CalendarEmployee } from '../Calendar/CalendarEmployee';
import { EmployeeStatus } from '../Employee/EmployeeStatus';
import { DataMainenancePage } from '../DataMaintenance/DataMaintenancePage';
import { useMediaQuery } from '../Utils/useMediaQuery'
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Sidemenu } from './Sidemenu';
import { EmployeesPage } from '../Employee/EmployeesPage';
import { EmployeePage } from '../Employee/EmployeePage';
import { OperationReport } from './OperationReport';
import { OperatorView } from './OperatorView';
import { ManageSimpleListPage } from '../DataMaintenance/ManageSimpleListPage';
import { Panel } from 'primereact/panel'


export const NavigationPage = ({ user, timeEntryCategoryList }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [screenSize] = useMediaQuery();
    const [visibleMenu, setVisibleMenu] = useState(false);
    const [component,setComponent] = useState();

    useEffect(()=>{
        setComponent(() =><div className="p-mr-2 p-as-stretch">Stretch</div>)
    },[])

    console.log('NavigationPage screenSize: ', screenSize)
    const renderSmallScreen = () => {
        return (<div className="p-grid p-align-stretch vertical-container" >
            <Accordion style={{ width: '100%', margin: 'auto', backgroundColor: 'grey' }} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {renderAccordionTabs()}
            </Accordion>
        </div>)
    }

    const renderLargeScreen = () => {
        return (
            <div className="p-d-flex"  style={{height:'100%'}}>
                {component}
            </div>
        )
    }
    const renderLargeScreenTabView = () => {
        return (
            <div className="p-col "  >
                <TabView id='nav-page-tabview'>
                    {renderTabViewPanels()}
                </TabView>
            </div>


        )
    }


    const renderAccordionTabs = () => {
        const tabs = [];
        if (user.employee) {
            tabs.push(
                <AccordionTab header='Status' key='accTabStatus'>
                    <EmployeeStatus employee={user.employee} />
                </AccordionTab>);
            tabs.push(
                <AccordionTab header='Kalender' key='accTabCalendar'>
                    <CalendarEmployee employee={user.employee} timeEntryCategoryList={timeEntryCategoryList} />
                </AccordionTab>);
        }
        if (user.roles.find(e => e.name === 'operatorView')) {
            tabs.push(
                <AccordionTab header='Kräfteübersicht' key='accTabOperatorView'>
                    <OperatorView/>
                </AccordionTab>);
        }
        return tabs.map(f => f);
    }

    const handleSidemenuCommand = (componentname) =>{
        switch(componentname){
            case 'EmployeePage': 
                setVisibleMenu(false)
                setComponent(() => <EmployeePage user={user} timeEntryCategoryList={timeEntryCategoryList}/>);
                break;
            case 'OperatorView': 
                setVisibleMenu(false)
                setComponent(() => <OperatorView/>);
                break;
            case 'EmployeesPage': 
                setVisibleMenu(false)
                setComponent(() => <EmployeesPage timeEntryCategoryList={timeEntryCategoryList}/>);
                break;
            case 'OperationReport': 
                setVisibleMenu(false)
                setComponent(() => <OperationReport/>);
                break;
            case 'ManageBusinessUnits': 
                setVisibleMenu(false)
                setComponent(() => <ManageSimpleListPage servicename='business-unit' />);
                break;
            case 'ManageSites': 
                setVisibleMenu(false)
                setComponent(() => <ManageSimpleListPage servicename='site' />);
                break;
            case 'ManageOrganizationFunctions': 
                setVisibleMenu(false)
                setComponent(() => <ManageSimpleListPage servicename='organization-function' />);
                break; 
            case 'ManageQualificationFunctions': 
                setVisibleMenu(false)
                setComponent(() => <ManageSimpleListPage servicename='qualification-function' />);
                break;   
        }
    }

    const renderTabViewPanels = () => {
        const panels = [];
        if (user.employee) {
            panels.push(
                <TabPanel key="Mein Kalender" header="Mein Kalender" style={{ backgroundColor: 'rgb(255,0,0,0.0)' }}>
                    <div className="p-grid" style={{ height: (screenSize[1] - 150) + 'px' }}>
                        <div className="p-col-fixed" style={{ width: '400px' }}>
                            <EmployeeStatus employee={user.employee} />
                        </div>
                        <div className="p-col" >
                            <CalendarEmployee employee={user.employee} timeEntryCategoryList={timeEntryCategoryList} />
                        </div>
                    </div>
                </TabPanel>);
        }
        if (user.roles.find(e => e.name === 'operatorView')) {
            panels.push(
                <TabPanel key="Einsatzkräfte" header="Einsatzkräfte">
                    
                </TabPanel>);
        }
        if (user.roles.find(e => e.name === 'EvD')) {
            panels.push(
                <TabPanel key="EvD" header="EvD">

                </TabPanel>);
        }
        if (user.roles.find(e => e.name === 'HR')) {
            panels.push(
                <TabPanel key="Stammdaten" header="Stammdaten">
                    <DataMainenancePage user={user} timeEntryCategoryList={timeEntryCategoryList} />
                </TabPanel>);
        }
        panels.push(
            <TabPanel key="operationReport" header="Auswertung Einsätze">
                <OperationReport/>
            </TabPanel>);
        return panels.map(f => f);
    }

    const getDivStyle = () => {
        return { height: (screenSize[1] - 90) + 'px', backgroundColor: 'rgb(0,0,0,0.2)' }
    }

    return (
        <>
            <div className="p-card p-d-flex p-flex-column" style={{ height: (screenSize[1]) + 'px' }}>
                <div className="p-panel-content" style={{ height: '50px', backgroundColor: 'rgb(200,200,200,0.2)' }}>
                    <div className="p-panel-content p-d-flex p-m-1 " >
                        <div className='p-mr-4'>
                            <Button icon="pi pi-bars" onClick={(e) => setVisibleMenu(true)} />
                        </div>
                        <div className="p-as-center">
                            <b><span style={{ color: 'white' }}>{(user ? user.employee.lastname + ', ' + user.employee.firstname : user.email)}</span></b>
                        </div>
                    </div>
                </div>
                <div className='p-card' style={{ height: (screenSize[1] - 52) + 'px'}}>
                    {screenSize[0] < 1200 ? renderSmallScreen() : renderLargeScreen()}
                </div>
            </div>
            <Sidemenu handleSidemenuCommand={handleSidemenuCommand} user={user} setVisibleMenu={setVisibleMenu} visibleMenu={visibleMenu} />
        </>
    );
}